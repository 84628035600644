var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      staticClass: "c-card--pa0",
      attrs: { radius: 12, link: "", tiled: _vm.tiled, noticed: _vm.noticed },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple",
              value: _vm.isLinked,
              expression: "isLinked",
            },
          ],
          style: !_vm.isLinked ? "pointer-events: none" : "",
          attrs: { href: _vm.href },
        },
        [
          _c("div", { staticClass: "c-card__inner" }, [
            _c(
              "div",
              { staticClass: "c-card__img c-card__img--lg" },
              [
                _vm.src
                  ? _c("img", { attrs: { src: _vm.src, alt: _vm.title } })
                  : _c("NoImage"),
              ],
              1
            ),
            _c("div", { staticClass: "c-card__content" }, [
              _c("div", { staticClass: "c-card__subtitle2" }, [
                _vm._v(" " + _vm._s(_vm.subtitle) + " "),
              ]),
              _c("div", { staticClass: "c-card__title2" }, [
                _vm._v(" " + _vm._s(_vm.title) + " "),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }